export const REACT_APP_SERVER_API = process.env.REACT_APP_SERVER_API
export const REACT_APP_HEAD_QUATER_PREFIX =
  process.env.REACT_APP_HEAD_QUATER_PREFIX
export const REACT_APP_BRANCH_PREFIX = process.env.REACT_APP_BRANCH_PREFIX
export const REACT_APP_ADMIN_PREFIX = process.env.REACT_APP_ADMIN_PREFIX
export const REACT_APP_END_USER = process.env.REACT_APP_END_USER
export const INTERVAL_refreshToken =
  Number(process.env.INTERVAL_refreshToken) || 250000
export const REACT_APP_BRANCH_PAGE = process.env.REACT_APP_BRANCH_PAGE
export const REACT_APP_PARENT_APP_DOMAIN =
  process.env.REACT_APP_PARENT_APP_DOMAIN
export const REACT_APP_MEDIA_DOMAIN = process.env.REACT_APP_MEDIA_DOMAIN
