import clsx from 'clsx'
import { useStore } from 'context/store'
import { observer } from 'mobx-react'
import { Card } from 'primereact/card'
import { useTranslation } from 'react-i18next'

const TopTenBranch = () => {
  const {
    headQuarterInfoStore: {
      selectedItem: { top10Branch },
    },
  } = useStore()
  const { t } = useTranslation()
  return (
    <div className=" mt-3 mb-4">
      <Card
        title={t('Top 10 branch')}
        className="w-full"
        style={{
          boxShadow: '0px 4px 30px 0px #DDE0FF8A',
        }}
      >
        <div className="w-fit">
          <table className="top-ten-table w-full">
            <tbody>
              {top10Branch.map((item, index) => (
                <tr
                  key={item.branchId}
                  className={clsx(index < 3 ? 'bold-text' : '')}
                >
                  <td style={{ width: 64 }}>{index + 1}</td>
                  <td style={{ width: 400 }}>{item.branchName}</td>
                  <td>
                    {item.eventCount} {t('events')}
                  </td>
                  <td>
                    {item.numberCustomers} {t('customers')}
                  </td>
                  <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  )
}

export default observer(TopTenBranch)
