export const LIMIT_DATALOAD_LIST = 10
export const DEFAULT_PAGE_TABLE_SIZE = 10
export const DEFAULT_LIMIT_SELECT = 10
export const TIMEOUT_LOAD_MORE = 1000
export const TIMEOUT_SEARCH = 1000
export const LIMIT_NOT_LAZY_SELECT = 2000

export const PRODUCT_BOARD_VIEW_TYPE = {
  LIST_VIEW: 'LIST_VIEW',
  GRID_VIEW: 'GRID_VIEW',
  BOARD_MAP_VIEW: 'BOARD_MAP_VIEW',
}
export enum TenantPlatformEnum {
  /**
   * Web
   */
  WEB = 1,
  /**
   * Mobile web
   */
  MOBILE_WEB = 2,
  /**
   * App
   */
  APP = 3,
}
